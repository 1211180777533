import * as React from 'react'
import Box from '@mui/material/Box'

export default function AnnualReturnBox({ rate }: { rate: number | null }) {
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      display="flex"
      component="section"
      sx={{ p: 1, width: 4 / 5 }}
      // sx={{ p: 2, borderRadius: 2, border: '1px solid grey' }}
    >
      Annual Return: {rate ? (rate * 100).toFixed(2) : '--'}%
    </Box>
  )
}
