import React, { useState } from 'react'
import { InvestmentReturn } from './types'
import './App.css'
import AnnualReturnBox from './AnnualReturnBox'
import PerformanceChart from './PerformanceChart'
import { fetchPerfData } from './fetchData'

function App() {
  const [perfData, setPerfData] = useState<InvestmentReturn | null>(null)

  if (!perfData) {
    fetchPerfData().then((investmentReturn) => {
      setPerfData(investmentReturn)
    })
  }

  return (
    <div className="container">
      <div className="annualReturn">
        <AnnualReturnBox rate={perfData ? perfData.annualReturn : null} />
      </div>
      <div className="perfChart">
        <PerformanceChart checkPoints={perfData ? perfData.checkPoints : []} />
      </div>
    </div>
  )
}

export default App
