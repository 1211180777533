import * as React from 'react'
import { LineChart } from '@mui/x-charts/LineChart'
import { CheckPoint } from './types'

export default function PerformanceChart({
  checkPoints,
}: {
  checkPoints: CheckPoint[]
}) {
  const baseAmount = 10_000
  const tenKReturns = checkPoints.map((checkPoint) => ({
    date: new Date(checkPoint.date).getTime(),
    value: baseAmount * (1 + checkPoint.value),
  }))
  return (
    <LineChart
      sx={{ p: 1 }}
      series={[
        { dataKey: 'value', label: 'Growth of $10,000', showMark: false },
      ]}
      xAxis={[
        {
          dataKey: 'date',
          valueFormatter: (d) => {
            return d ? new Date(d).toLocaleDateString() : ''
          },
        },
      ]}
      dataset={tenKReturns}
    />
  )
}
